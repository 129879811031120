@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Damion";
  src: url("../assets/font/Damion/Damion-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Droid Serif";
  src: url("../assets/font/DroidSerif/DroidSerif-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lobster";
  src: url("../assets/font/Lobster/Lobster-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lobster Two";
  src: url("../assets/font/LobsterTwo/LobsterTwo-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Monoton";
  src: url("../assets/font/Monoton/Monoton-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSans";
  src: url("../assets/font/OpenSans/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSans ExtraBold";
  src: url("../assets/font/OpenSansExtraBold/OpenSansExtraBold-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans Light";
  src: url("../assets/font/OpenSansLight/OpenSansLight-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSans SemiBold";
  src: url("../assets/font/OpenSansSemiBold/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Oswald";
  src: url("../assets/font/Oswald/Oswald-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Oswald Light";
  src: url("../assets/font/OswaldLight/OswaldLight-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Pacifico";
  src: url("../assets/font/Pacifico/Pacifico-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Pinyon Script";
  src: url("../assets/font/PinyonScript/PinyonScript-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/font/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Black";
  src: url("../assets/font/RobotoBlack/RobotoBlack-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Light";
  src: url("../assets/font/RobotoLight/RobotoLight-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Medium";
  src: url("../assets/font/RobotoMedium/RobotoMedium-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Thin";
  src: url("../assets/font/RobotoThin/RobotoThin-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Rokkitt";
  src: url("../assets/font/Rokkitt/Rokkitt-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Share Tech Mono";
  src: url("../assets/font/ShareTechMono/ShareTechMono-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Unkempt";
  src: url("../assets/font/Unkempt/Unkempt-Regular.ttf") format("truetype");
}

body,
html,
#__next {
  @apply h-screen bg-white text-[#141414];
  font-display: optional;
  text-wrap: pretty;
  /* font-family: "Poppins"; */
}

body:not(:has(.hide-header)) {
  padding-top: 94px;
}

html:has(.headless-modal) {
  overflow: hidden;
}

body:has(.hide-footer) .footer-content {
  display: none;
}

body:has(.hide-header) .header-content {
  display: none;
}

@media only screen and (max-width: 1023px) {
  body:has(.designer-tool) footer {
    display: none;
  }
}

@layer components {
  .crop-type-mosaic-button span {
    overflow: visible !important;
  }

  .png-drop-shadow {
    -webkit-filter: drop-shadow(0px 0px 4px #00000090);
    filter: drop-shadow(0px 0px 4px #00000090);
  }

  .png-drop-shadow-sm {
    -webkit-filter: drop-shadow(0px 0px 2px #00000050);
    filter: drop-shadow(0px 0px 2px #00000050);
  }

  .text-shadow {
    text-shadow: 1px 1px 0px #00000070;
  }

  .image-block > span {
    display: block !important;
  }

  .link {
    @apply text-sm text-blue-600 underline cursor-pointer hover:text-blue-700;
    @apply flex flex-row items-center;
  }

  .link-inline {
    @apply text-sm text-blue-600 underline cursor-pointer hover:text-blue-700;
  }

  .card {
    @apply border border-gray-100;
    @apply p-6 overflow-hidden bg-white rounded-md shadow-lg;
    border-radius: 24px;
  }

  .zigzag-sides {
    position: relative;
    background: #fafafa;
    height: 100px;
  }

  .bg-white.zigzag-sides::after {
    background: linear-gradient(-45deg, white 10px, transparent 0),
      linear-gradient(45deg, white 10px, transparent 0);
    transform: rotate(-90deg);
    background-size: 20px 15px;
    background-repeat: repeat-x;
  }

  .bg-white.zigzag-sides::before {
    background: linear-gradient(-45deg, white 10px, transparent 0),
      linear-gradient(45deg, white 10px, transparent 0);
    transform: rotate(90deg);
    background-size: 20px 15px;
    background-repeat: repeat-x;
  }

  .zigzag-sides:after {
    background: linear-gradient(-45deg, #fafafa 10px, transparent 0),
      linear-gradient(45deg, #fafafa 10px, transparent 0);
    transform: rotate(-90deg);
    background-size: 20px 15px;
    background-repeat: repeat-x;
    content: " ";
    display: block;
    position: absolute;
    bottom: 0px;
    left: -11px;
    width: 100px;
    height: 100%;
  }

  .zigzag-sides:before {
    background: linear-gradient(-45deg, #fafafa 10px, transparent 0),
      linear-gradient(45deg, #fafafa 10px, transparent 0);
    transform: rotate(90deg);
    background-size: 20px 15px;
    background-repeat: repeat-x;
    content: " ";
    display: block;
    position: absolute;
    bottom: 0px;
    right: -11px;
    width: 100px;
    height: 100%;
  }

  .blue-button,
  .primary-button {
    @apply cursor-pointer;
    @apply inline-flex justify-center border border-transparent rounded-2xl;
    @apply px-4 py-2 text-base font-medium text-white bg-[#2463EB] shadow-sm;
    @apply hover:bg-blue-500 sm:text-sm outline-blue-900;
    @apply disabled:bg-gray-400 disabled:cursor-not-allowed;
    @apply transition-all duration-100;
    @apply text-sm lg:text-base;
  }

  .red-button {
    @apply cursor-pointer;
    @apply inline-flex justify-center border border-transparent rounded-2xl;
    @apply px-4 py-2 text-base font-medium text-white bg-red-600 shadow-sm;
    @apply hover:bg-red-700 sm:text-sm outline-red-900;
    @apply disabled:bg-gray-400 disabled:cursor-not-allowed;
    @apply transition-all duration-100;
    @apply text-sm lg:text-base;
  }

  .clear-button,
  .secondary-button {
    @apply cursor-pointer;
    @apply inline-flex justify-center border border-gray-300 rounded-2xl;
    @apply px-4 py-2 text-base font-medium text-gray-700 bg-white shadow-sm;
    @apply sm:text-sm outline-gray-400;
    @apply disabled:bg-transparent disabled:text-gray-400 disabled:cursor-not-allowed;
    @apply transition-all duration-100;
    @apply text-sm lg:text-base items-center;

    background: transparent;
  }

  .clear-button:hover,
  .secondary-button:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  .white-button {
    @apply cursor-pointer;
    @apply inline-flex justify-center border border-gray-300 rounded-2xl;
    @apply px-4 py-2 text-base font-medium text-gray-700 bg-white shadow-sm;
    @apply sm:text-sm outline-gray-400 hover:bg-gray-100;
    @apply disabled:bg-white disabled:text-gray-400 disabled:cursor-not-allowed;
    @apply transition-all duration-100;
    @apply text-sm lg:text-base items-center;
  }

  .link-button,
  .tertiary-button {
    @apply cursor-pointer;
    @apply inline-flex justify-center;
    @apply py-2 text-base text-blue-600 bg-transparent;
    @apply hover:text-blue-700 sm:text-sm outline-gray-400;
    @apply disabled:text-gray-400 disabled:cursor-not-allowed;
    @apply transition-all duration-100;
    @apply text-sm lg:text-base items-center;
  }

  .crop-by-hand-gif {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .crop-error-modal {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .crop-by-hand-gif img {
    border-radius: 18px;
    object-fit: cover;
    height: 250px !important;
    width: 200px !important;
  }

  .tooltips {
    position: relative;
    outline: none;
    display: inline;
  }

  .tooltips span {
    transition: opacity 0.3s;
    position: absolute;
    opacity: 0;
    white-space: nowrap;
    width: max-content;
    padding: 3px 10px;
    color: #ffffff;
    background: #3b82f6;
    text-align: center;
    visibility: hidden;
    border-radius: 6px;
    text-indent: 0;
    left: 50%;
  }

  .tooltips.tooltip-arrow-right span:after {
    left: calc(100% - 25px);
  }

  .tooltips.tooltip-arrow-left span:after {
    left: 15px;
  }

  .tooltips.tooltip-arrow-center span:after {
    left: 50%;
  }

  .tooltips.tooltip-arrow-bottom span:after {
    bottom: 0px;
    transform: translate(-50%, 100%);

    border-top: 8px solid #3b82f6;
    border-bottom: 0px;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }

  .tooltips.tooltip-arrow-top span:after {
    bottom: 100%;
    border-bottom: 8px solid #3b82f6;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }

  .tooltips span:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-bottom: 8px solid #3b82f6;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }

  button:hover.tooltips span {
    visibility: visible;
    opacity: 1;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, calc(100% + 8px));
    z-index: 999;
    transition: opacity 0.3s;
  }

  a:hover.tooltips span {
    visibility: visible;
    opacity: 1;
    bottom: 0px;
    transform: translate(-50%, calc(100% + 8px));
    z-index: 999;
    transition: opacity 0.3s;
  }

  .ReactCrop .ord-nw:after {
    top: -5px !important;
    left: -5px !important;
    width: 20px !important;
    height: 20px !important;
  }

  .ReactCrop .ord-ne:after {
    top: -5px !important;
    right: -5px !important;
    width: 20px !important;
    height: 20px !important;
  }

  .ReactCrop .ord-n:after {
    top: -5px !important;
    width: 20px !important;
    height: 20px !important;
  }

  .ReactCrop .ord-e:after {
    right: -5px !important;
    width: 20px !important;
    height: 20px !important;
  }

  .ReactCrop .ord-w:after {
    left: -5px !important;
    width: 20px !important;
    height: 20px !important;
  }

  .ReactCrop .ord-sw:after {
    bottom: -5px !important;
    left: -5px !important;
    width: 20px !important;
    height: 20px !important;
  }
  .ReactCrop .ord-se:after {
    bottom: -5px !important;
    right: -5px !important;
    width: 20px !important;
    height: 20px !important;
  }

  .ReactCrop .ord-s:after {
    bottom: -5px !important;
    width: 20px !important;
    height: 20px !important;
  }

  .copy-paste-btn {
    background: transparent url("../assets/svg/copy-paste-btn.svg") no-repeat;
    background-size: cover;
  }

  .copy-other-side-btn {
    background: transparent url("../assets/svg/copy-other-side-btn.svg") no-repeat;
    background-size: cover;
  }
  .bold-btn {
    background: transparent url("../assets/svg/bold-btn.svg") no-repeat;
    background-size: cover;
  }
  .italic-btn {
    background: transparent url("../assets/svg/italic-btn.svg") no-repeat;
    background-size: cover;
  }
  .bullet-point-btn {
    background: transparent url("../assets/svg/bullet-point-btn.svg") no-repeat;
    background-size: cover;
  }

  .align-bottom-btn {
    background: transparent url("../assets/svg/align-bottom-btn.svg") no-repeat;
    background-size: cover;
  }
  .align-top-btn {
    background: transparent url("../assets/svg/align-top-btn.svg") no-repeat;
    background-size: cover;
  }
  .align-right-btn {
    background: transparent url("../assets/svg/align-right-btn.svg") no-repeat;
    background-size: cover;
  }
  .align-left-btn {
    background: transparent url("../assets/svg/align-left-btn.svg") no-repeat;
    background-size: cover;
  }
  .align-center-btn {
    background: transparent url("../assets/svg/align-center-btn.svg") no-repeat;
    background-size: cover;
  }
  .align-middle-btn {
    background: transparent url("../assets/svg/align-middle-btn.svg") no-repeat;
    background-size: cover;
  }
  .layer-up-btn {
    background: transparent url("../assets/svg/layer-up-btn.svg") no-repeat;
    background-size: cover;
  }
  .layer-down-btn {
    background: transparent url("../assets/svg/layer-down-btn.svg") no-repeat;
    background-size: cover;
  }
  .scale-plus-btn {
    background: transparent url("../assets/svg/scale-plus-btn.svg") no-repeat;
    background-size: cover;
  }
  .scale-minus-btn {
    background: transparent url("../assets/svg/scale-minus-btn.svg") no-repeat;
    background-size: cover;
  }
  .text-align-left-btn {
    background: transparent url("../assets/svg/format-align-left-btn.svg") no-repeat;
    background-size: cover;
  }
  .text-align-right-btn {
    background: transparent url("../assets/svg/format-align-right-btn.svg") no-repeat;
    background-size: cover;
  }
  .text-align-center-btn {
    background: transparent url("../assets/svg/format-align-center-btn.svg") no-repeat;
    background-size: cover;
  }

  .add-image-cta {
    background: transparent url("../assets/svg/add-image-cta.svg") no-repeat;
    background-size: cover;
  }

  .add-text-cta {
    background: transparent url("../assets/svg/add-text-cta.svg") no-repeat;
    background-size: cover;
  }

  .add-bg-cta {
    background: transparent url("../assets/svg/add-bg-cta.svg") no-repeat;
    background-size: cover;
  }

  .add-image-btn {
    background: transparent url("../assets/svg/add-image-btn.svg") no-repeat;
    background-size: cover;
  }

  .add-text-btn {
    background: transparent url("../assets/svg/add-text-btn.svg") no-repeat;
    background-size: cover;
  }

  .bg-color-btn {
    background: transparent url("../assets/svg/bg-color-btn.svg") no-repeat;
    background-size: cover;
  }

  .format-btn {
    background: transparent url("../assets/svg/format-btn.svg") no-repeat;
    background-size: cover;
  }

  .copy-btn {
    background: transparent url("../assets/svg/copy-paste-btn.svg") no-repeat;
    background-size: cover;
  }

  .default-input {
    @apply block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm;
    @apply focus:outline-none focus:border-blue-500 sm:text-sm;
  }

  .custom-phone-input {
    @apply block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm;
    @apply focus:outline-none focus:border-blue-500 bg-white;
  }

  .custom-phone-input:has(> input:focus) {
    @apply border-blue-500;
  }

  .custom-phone-input input {
    @apply focus:outline-none sm:text-sm ml-2;
  }

  .default-select {
    @apply py-[0.61rem] px-3 block border w-full bg-white border-gray-300 rounded-md;
    @apply shadow-sm focus:outline-none focus:border-blue-500 sm:text-sm;
  }

  .png-white-border {
    -webkit-filter: drop-shadow(2px 1px 0 black) drop-shadow(-1px -1px 0 black);
    filter: drop-shadow(2px 1px 0 black) drop-shadow(-1px -1px 0 black);
  }

  .word-break {
    word-break: break-word;
  }

  .link-disabled {
    pointer-events: none;
  }

  .rtl {
    direction: rtl;
  }

  .dropzone {
    background: #fafafa;
    border: 3px dashed #d0d0d0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-top: 20px;
    cursor: pointer;
    transition: 0.1s ease;
    @apply py-[20px] sm:h-[8vw] sm:mt-[20px] mt-3;
  }

  .dropzone:hover {
    background: #f7f7f7;
  }

  .dropzone p {
    @apply link-button font-semibold;
  }

  .payment-options-container {
    @apply flex flex-col md:grid  grid-cols-2 gap-3;
  }

  .radio-option input {
    @apply mr-3;
  }

  .radio-option {
    @apply clear-button;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10px;
    width: 100%;
    flex: 1;
  }

  .dash-lines__shape svg {
    height: 320px;
    width: 285px;
  }

  .bleed-dash {
    stroke: red !important;
  }

  .safe-dash path {
    stroke: green !important;
    fill: white;
  }

  .no-arrows {
    -moz-appearance: textfield;
  }

  .no-arrows::-webkit-inner-spin-button,
  .no-arrows::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.paypal-buttons-label-pay {
  border-radius: 1rem !important;
  overflow: hidden;

  @media (max-width: 700px) {
    height: unset !important;
  }
}

div:has(> div.paypal-buttons-label-pay) {
  @media (max-width: 700px) {
    background: #ffc439;
  }
}
